<template>
  <div class="developer-bank-account">
    <div class="bordered-card p-2" :class="cardClass">
      <p class="developer-bank-account__title mb-1">{{ bankAccount.bank }}</p>
      <p class="developer-bank-account__content">
        {{ bankAccount.bankAccountName }}
      </p>
      <p class="developer-bank-account__content">
        {{ bankAccount.bankAccountNumber }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    bankAccount: {
      type: Object,
      required: true
    },
    cardClass: {
      type: [String, Object, Array],
      default: ""
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.developer-bank-account {
  transition: all 0.3s;
  .developer-bank-account__title {
    font-weight: bold;
    font-size: 16px;
  }
  .developer-bank-account__content {
    font-weight: normal;
  }
}
</style>
