<template>
  <div
    class="developer-bank-account-card-selector cursor-pointer"
    @click="toggleSelect"
  >
    <bank-account-card
      :key="bankAccount.id"
      :bankAccount="bankAccount"
      :cardClass="{ selected: isSelected }"
    ></bank-account-card>
  </div>
</template>

<script>
import BankAccountCard from "@/components/GlobalComponents/Shared/Developer/DeveloperBankAccountCard";

export default {
  components: { BankAccountCard },
  mixins: [],
  props: {
    bankAccount: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: true
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    toggleSelect() {
      this.isSelected ? this.$emit("deselect") : this.$emit("select");
    }
  }
};
</script>

<style lang="scss">
.developer-bank-account-card-selector {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  .selected {
    transition: all 0.3s;
    border: solid 2px $color-main;
    border-radius: 6px;
    box-shadow: 0px 0px 3px 0px lighten($color-main, 40);
  }
}
</style>
